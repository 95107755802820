import logo from './logo.svg';
import './App.scss';
import'./MediaQ.scss';
import Home from './components/Home';


function App() {
  return (
    <div className="App">
      <Home/>
    </div>
  );
}

export default App;
